import styled, { css } from 'styled-components'
import { ToastContainer } from 'react-toastify'

export const CloseButton = styled.button`
  align-self: center;
  background-color: transparent;
  background: #ffffff;
  border-radius: 100%;
  border: none;
  box-shadow: 0px 10px 20px 0px #0000001a;
  cursor: pointer;
  display: flex;
  margin: 0;
  outline: none;
  padding: 4px;
  position: absolute;
  right: 0px;
  top: 0px;
  transform: translate(50%, -50%);
`

export const StyledToastContainer = styled(ToastContainer)(
  ({ theme: { colors } }) => css`
    transition: all 0.5s;
    width: max-content;

    @media (max-width: 480px) {
      margin-bottom: 16px;
      max-width: 320px;
      min-width: 300px;
    }

    .Toastify {
      &__toast {
        align-items: center;
        border-radius: 3px;
        display: flex;
        line-height: 15.6px;
        min-height: 42px;
        overflow: visible;
        padding: 16px 44px 16px 34px;
        text-align: left;
        text-transform: uppercase;
        white-space: nowrap;
        color: ${colors.textTertiary};

        @media (max-width: 480px) {
          margin-bottom: 16px;
          padding: 16px 72px 16px 24px;
        }

        &--success {
          background: rgba(0, 196, 185, 0.9);
        }

        &--error {
          background: rgba(244, 89, 135, 0.9);
        }
      }

      &__toast-body {
        margin: 0;
        padding: 0;

        div {
          line-height: 1;
        }
      }
    }
  `
)
