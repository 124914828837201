import { yupResolver } from '@hookform/resolvers/yup'
import * as Common from 'components/_common/Common'
import { KioskViewContainer, transparentCss } from 'components/_common/Common'
import NextButton from 'components/_common/NextButton'
import {
  notify,
  ToastTypes,
} from 'components/_common/ToastNotification/ToastNotification'
import * as Typography from 'components/_common/TypographyKiosk'
import InputText from 'components/_inputs/InputText'
import {
  ROUTE_CHECKIN_CONFIRM_EMAIL,
  ROUTE_CHECKIN_RESERVATION_DETAILS,
  ROUTE_CHECKOUT_RESERVATION_DETAILS,
} from 'constants/routes'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  selectReservationDetails,
  selectVerifyStatus,
  selectReservationState,
} from 'redux/slices/reservation/reservationSelectors'
import { verifyReservation } from 'redux/slices/reservation/reservationSlice'
import { selectUserDetails } from 'redux/slices/user/userSelector'
import { fetchUser } from 'redux/slices/user/userSlice'
import styled, { css, useTheme } from 'styled-components'
import { confirmReservationSchema } from 'utils/validation/search'

export const FormContainer = styled(Common.Div)<{
  isInputOpened?: boolean
}>(
  () => css`
    padding: 38px 105px;
    height: 372px;
    width: 920px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    ${transparentCss};
  `
)

const ViewCheckInOutConfirmEmailKiosk = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const reservation = useSelector(selectReservationDetails)
  const verifyReservationStatus = useSelector(selectVerifyStatus)
  const reservationState = useSelector(selectReservationState)
  console.log('verifyReservationStatus', verifyReservationStatus)
  console.log('reservationState', reservationState)
  const userDetails = useSelector(selectUserDetails)

  const confirmationForm = useForm({
    mode: 'all',
    defaultValues: {
      confirmationNumber: '',
    },
    resolver: yupResolver(confirmReservationSchema(t)),
  })

  const handleSearch = (data: { confirmationNumber: string }) => {
    if (reservation && reservation.users_uuid) {
      dispatch(
        verifyReservation({
          uuid: reservation.users_uuid,
          code: data.confirmationNumber,
        })
      )

      if (
        pathname === ROUTE_CHECKIN_CONFIRM_EMAIL &&
        verifyReservationStatus === 'succeeded'
      ) {
        navigate(ROUTE_CHECKIN_RESERVATION_DETAILS)
      } else {
        notify(t('findReservation.somethingWrong'))[ToastTypes.ERROR]()
      }
      // else {
      //   navigate(ROUTE_CHECKOUT_RESERVATION_DETAILS)
      // }
    }
  }

  useEffect(() => {
    if (reservation) {
      dispatch(fetchUser(reservation.users_uuid))
    }
  }, [dispatch, reservation?.users_uuid])

  return (
    <KioskViewContainer bgUrl={theme.assets.searchBackground}>
      <Common.Div
        flex="row"
        justifyContent="center"
        w="100%"
        mt={12}
      >
        <FormContainer>
          <Common.Div
            flex="row"
            justifyContent="space-between"
          >
            <div>
              <Typography.Body
                s={16}
                secondary
                mb={2}
              >
                {t('confirmReservation.hello')}
              </Typography.Body>
              <Typography.Body
                s={40}
                mb={4}
                secondary
              >
                {`${userDetails?.first_name || ''} ${
                  userDetails?.last_name || ''
                }`}
              </Typography.Body>
            </div>
            <Common.Div
              flex="column"
              gap="8px"
            >
              <Typography.Body
                s={16}
                secondary
              >
                {t('confirmReservation.yourReservationNumber')}
              </Typography.Body>
              <Typography.Body
                s={24}
                secondary
              >
                {reservation?.reservation_code || ''}
              </Typography.Body>
            </Common.Div>
          </Common.Div>
          <Common.Div flex="column">
            <Typography.Body
              s={16}
              mb={5.5}
              secondary
            >
              <Trans i18nKey="confirmReservation.almostDone" />
            </Typography.Body>
            <form onSubmit={confirmationForm.handleSubmit(handleSearch)}>
              <Common.Div
                flex="row"
                gap="16px"
              >
                <InputText
                  name="confirmationNumber"
                  form={confirmationForm}
                  w="529px"
                  secondary
                />
                <NextButton
                  disabled={verifyReservationStatus === 'loading'}
                  type="submit"
                />
              </Common.Div>
            </form>
          </Common.Div>
        </FormContainer>
      </Common.Div>
    </KioskViewContainer>
  )
}

export default ViewCheckInOutConfirmEmailKiosk
