import { yupResolver } from '@hookform/resolvers/yup'
import iconQr from 'assets/icons/iconQr.svg'
import * as Common from 'components/_common/Common'
import { KioskViewContainer, transparentCss } from 'components/_common/Common'
import NextButton from 'components/_common/NextButton'
import {
  notify,
  ToastTypes,
} from 'components/_common/ToastNotification/ToastNotification'
import * as Typography from 'components/_common/TypographyKiosk'
import InputText from 'components/_inputs/InputText'
import {
  ROUTE_CHECKIN_CONFIRM_EMAIL,
  ROUTE_CHECKIN_FIND_RESERVATION,
} from 'constants/routes'
import { useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { selectIsKiosk } from 'redux/slices/app'
import {
  selectReservationDetails,
  selectReservationStatus,
} from 'redux/slices/reservation/reservationSelectors'
import { fetchReservationDetails } from 'redux/slices/reservation/reservationSlice'
import styled, { css, useTheme } from 'styled-components'
import { IFindReservationForm } from 'types/room'
import { searchSchema } from 'utils/validation/search'

export const FormContainer = styled(Common.Div)<{
  isInputOpened?: boolean
}>(
  ({ theme: { colors } }) => css`
    padding: 38px 105px;
    height: 202px;
    width: 920px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid ${colors.border};
    gap: 42px;
    ${transparentCss};
  `
)

const ViewCheckInOutReservationNumber = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isKiosk = useSelector(selectIsKiosk)
  const { pathname } = useLocation()
  const reservationStatus = useSelector(selectReservationStatus)
  const reservationDetails = useSelector(selectReservationDetails)

  const searchForm = useForm<IFindReservationForm>({
    mode: 'all',
    defaultValues: {
      resNumber: '',
    },
    resolver: yupResolver(searchSchema(t)),
  })

  const handleSearch: SubmitHandler<IFindReservationForm> = (
    data: IFindReservationForm
  ) => {
    dispatch(fetchReservationDetails(data.resNumber))
  }

  useEffect(() => {
    if (reservationStatus === 'succeeded' && reservationDetails) {
      if (pathname === ROUTE_CHECKIN_FIND_RESERVATION) {
        navigate(ROUTE_CHECKIN_CONFIRM_EMAIL)
      } else {
        // notify(t('findReservation.somethingWrong'))[ToastTypes.ERROR]()
      }
    } else if (reservationStatus === 'failed') {
      notify(t('findReservation.somethingWrong'))[ToastTypes.ERROR]()
    }
  }, [reservationStatus, reservationDetails, navigate, pathname])

  return (
    <KioskViewContainer bgUrl={theme.assets.searchBackground}>
      <Typography.H1
        mt={6}
        mb={10}
        align="center"
        tertiary
      >
        {t('findReservation.header')}
        <br />
        {t('findReservation.subHeader')}
      </Typography.H1>

      <Common.Div
        flex="row"
        justifyContent="center"
        w="100%"
      >
        <FormContainer>
          <Common.Div
            flex="column"
            gap="42px"
          >
            <form onSubmit={searchForm.handleSubmit(handleSearch)}>
              <Common.Div
                flex="row"
                gap="16px"
              >
                <InputText
                  name="resNumber"
                  form={searchForm}
                  w="360px"
                  secondary
                />
                <NextButton
                  type="submit"
                  disabled={reservationStatus === 'loading'}
                />
              </Common.Div>
            </form>
            {isKiosk && (
              <Common.Div
                flex="row"
                gap="36px"
              >
                <Common.SvgIcon
                  src={iconQr}
                  w="40px"
                  h="40px"
                />
                <Typography.Body secondary>
                  <Trans i18nKey="findReservation.qrDesc" />
                </Typography.Body>
              </Common.Div>
            )}
          </Common.Div>
        </FormContainer>
      </Common.Div>
    </KioskViewContainer>
  )
}

export default ViewCheckInOutReservationNumber
